<template>
  <div class="image-header">
    <slot></slot>
    <!-- <img :src="require(img)"> -->
    <h1 class="image-header-text text-light text--white">{{text}}</h1>
  </div>
</template>

<script>
export default {
  props: {
    text: '',
    src: '',
  },
  data() {
    return {
      img: '@/assets/Header_Om_oss.jpg'
    }
  },
  methods: {
    getImgUrl(url) {
      var images = require.context('../assets/', false)
      return images(url)
    }
  }
}
</script>

<style>
.image-header {
  position: relative;
}

.image-header > img {
  width: 100%;
  min-height: 200px;
  height: 20vw;
  object-fit: cover;
}

.image-header-text {
  width: max-content;
  max-width: 90%;
  box-sizing: border-box;
  position: absolute;
  padding: 10px 10px;
  margin: 0;
  bottom: 10%;
  right: 50%;
  transform: translate(50%, 0);
  background-color: rgba(0, 0, 0, 0.596);
}
</style>